import { useSelector } from "react-redux";

export const useWeb = () => {
  const config = useSelector(s => s.data?.modules?.webTouchpoint);
  const backgroundUrl = config?.backgroundPublicUrl;
  const logoUrl = config?.logoPublicUrl;
  const showExternalSearch = Boolean(config?.carSearchPage === '1');
  const showCategories = Boolean(config?.categoriesPage === '1');
  const showInternalSearch = Boolean(config?.searchPage === '1');
  const showBigButtons = Boolean(config?.buttonsPage === '1');
  const isWebDisabled = config?.enabled === '0';
  const frontPage = config?.frontPage || '';
  return { showExternalSearch, showCategories, showInternalSearch, showBigButtons, frontPage, isWebDisabled, backgroundUrl,logoUrl};
};
